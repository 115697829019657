import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Logo_de from '../../../components/NiG_Logo_VERORTUNG_Südtirol.png';
import Logo_it from '../../../components/NiG_Logo_VERORTUNG_Südtirol Alto Adige.png';
import { useTranslation } from "react-i18next";
import { createTheme } from '@mui/material/styles';
import { Title } from '../../../components/Title';

const theme = createTheme({
  typography: {
    gtitle: {
      fontSize: '42pt',
      fontWeight: 600,
      color: '#00652b',
      lineHeight: '42pt',
      '@media (max-width: 992px)': {
        fontSize: '32pt',
      },
    },
    text: {
      fontSize: '18pt',
      color: 'black',
      '@media (max-width: 992px)': {
        fontSize: '16pt',
      },
    }
  },

});


const anchors = document.querySelectorAll('a[href*="#"]')

for (let anchor of anchors) {
  anchor.addEventListener("click", function (event) {
    event.preventDefault();
    const blockID = anchor.getAttribute('href')
    document.querySelector('' + blockID).scrollIntoView({
      behavior: "smooth",
      block: "start"
    })
  })
}

export default function UnsAngPriv() {

  const { i18n, t } = useTranslation();

  return (

    <>
      <Box id="private_garden" className='maintextbox' sx={{
        marginTop: 2,
      }}>

        <Title title="offer_for_garden" variant="title"></Title>

        <Box className='pad' />

        <Typography variant='text' paragraph={true} theme={theme}>
          {t('offer_for_garden_1')}
        </Typography>
        <br />
        <Typography variant='text' paragraph={true} theme={theme}>
          {t('offer_for_garden_2')}
        </Typography>

        <Box sx={{ paddingBottom: 2 }} />

        <img src={i18n.language==="de" ? Logo_de : Logo_it} width='380px' alt="logo" />

        <br />
      </Box>
    </>

  );
}