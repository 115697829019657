import * as React from 'react';
import Box from '@mui/material/Box';
import Logo from './Links/Laimburg.png';
import IconButton from '@mui/material/IconButton';
import { TfiFacebook } from "react-icons/tfi";
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import TextGreen from './TextGreen';

const AButton = styled(Button)({
    padding: '2px',
    borderRadius: 0,
    color: 'black',
    textTransform: 'capitalize',
    fontFamily: 'inherit',
    fontSize: '12pt'
});

export function Laimburg() {

    const { t, i18n } = useTranslation();

    const [open, setOpen] = React.useState(false);

    let link_a = i18n.language === 'de' ? "https://www.laimburg.it/de/ueber-uns/allgemeine-bestimmungen.asp" : "https://www.laimburg.it/it/chi-siamo/disposizioni-generali.asp";
    let link_p = i18n.language === 'de' ? "https://www.laimburg.it/de/privacy.asp" : "https://www.laimburg.it/it/privacy.asp";


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

    return (
        <>
            <Grid item xs={12} sm={12} md={4} sx={{ padding: '20px' }} id="laimburg">

                <Grid item sx={{ paddingTop: '20px' }}>

                    <Box className='laimburg-logo'>
                        <img src={Logo} width='300px' alt="logo" />
                    </Box>
                        <Typography className='footerlinks' sx={{ paddingLeft: 1, color: '#00652b' }}>
                    	    {t('nig_email')}
                        </Typography>
                </Grid>

                <Grid container >
                    <Grid item sm={12} className='footerlinks'>

                        <Link href="https://www.facebook.com/Laimburg.Research.Centre" target="_blank">
                            <IconButton >
                                <TfiFacebook id='fbicon' />
                            </IconButton>
                        </Link>

                        <br />

                        <Typography className='footerlinks' sx={{ paddingLeft: 1 }}>
                            {t('laimburg_address')}
                            <br />
                            {t('laimburg_email')}
                            <br />
                            {t('laimburg_phone')}
                        </Typography>
                    </Grid>

                    <Grid item sm={12} className='footerlinks' sx={{ paddingTop: '20px' }}>

                        <AButton className='footerlinks' onClick={handleClickOpen}>{t('impressum')} </AButton>
                        |
                        <Link href={link_a} target="_blank">
                            <AButton className='footerlinks'>{t('agb')}</AButton>
                        </Link>
                        |
                        <Link href={link_p} target="_blank">
                            <AButton className='footerlinks'>{t('privacy')}</AButton>
                        </Link>

                    </Grid>
                </Grid>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {t('impressum_title')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        {t('impressum_body')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} autoFocus>
                        {t('close')}
                        </Button>
                    </DialogActions>
                </Dialog>

            </Grid>

        </>
    )
}

export default Laimburg;