import * as React from 'react';
import { useState, useEffect } from 'react';
import mainImage from '../../components/Links/IMG_0655.JPG';
import Box from '@mui/material/Box';
import CardVeranstal from './EventsPrime';
import { useLocation } from 'react-router-dom';
import CardVeranst from './EventsItem';
import { useTranslation } from "react-i18next";
import { useMatomo } from '@datapunt/matomo-tracker-react'

//import data_de from './events_de.json';
//import data_it from './events_it.json';


export default function Veranstaltungen() {

  const location = useLocation();
  const { i18n } = useTranslation();

  const [extend, setExtend] = useState(false);

  const [list, setList] = useState([]);

  //const [cards, setCards] = useState(list);

  const { trackPageView } = useMatomo()

  function convert (item)
    {
	if (item === undefined)
	    return ""

	let d = item.split('.');
	return d[2]+"-"+d[1]+"-"+d[0];
    }

  // Track page view
  React.useEffect(() => {
    trackPageView({
      documentTitle: 'Veranstaltungen', // optional
      href: 'https://naturimgarten.laimburg.it' + location.pathname, // optional
      customDimensions: [
        {
          id: 1,
          value: 'loggedIn',
        },
      ], // optional
    })
  }, [trackPageView])

  useEffect(() => {

    fetch(i18n.language==='de' ? 'Events/events_de.json' : 'Events/events_it.json')
      .then(response => response.json())
      .then(
          response => {
	  let datum = new Date();
          let l = response.filter((item) => (new Date(convert(item.expire)) > new Date() || item.expire === undefined) )
	  setList(l);
	    }
        )
      .catch(error => {
          // handle the error
      });

  }, [i18n.language])

  const [id, setId] = useState(null);

  useEffect(() => {
    if (location.search !== undefined) {

      var param = new URLSearchParams(location.search).get('id')
     
      setId(param);
    }
  }, [location.search])


  useEffect(() => {
    let newarray = []

    list.map((item, index) => {

      if (extend)
        newarray.push(item);
      else
        if (index < 2)
          newarray.push(item)
      return 0;
    })

    //setCards(newarray);
  }, [extend, list])


  return (
    <Box>

    {id === null && 
      <Box className='mainimagebox' >
        <img className='mainimage' alt="IMG_0655.JPG"
          src={mainImage}
          width='100%'
        />
      </Box>
      }

      <Box>
        {id === null ? <CardVeranstal list={list} /> : ''}

        {id !== null ? <CardVeranst item={list[id-1]} /> : ''}
      </Box>

      <Box id='linebox'>

      </Box>
      <Box id='t'></Box>

    </Box>
  );
}

