import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { Title } from '../../components/Title';

const theme = createTheme({
  typography: {
    gtitle: {
      fontSize: '42pt',
      fontWeight: 600,
      color: '#00652b',
      lineHeight: '42pt',
      '@media (max-width: 992px)': {
        fontSize: '32pt',
      },
    },
    text: {
      fontSize: '16pt',
    }
  },

});

export default function UnsAngGem() {

  const { t } = useTranslation();

  return (
    <Box id="offer_gemeinde" className='maintextbox' sx={{
      marginTop: 2,
    }}>

      <Title title="offer_municipality" variant="title"></Title>

      <Box className='pad' />
      
      <Typography variant='text' theme={theme}>
      {t('offer_municipality_1')}
      <Box sx={{paddingBottom: 2}} />
      
      <Box className='textgb'>
        {t('offer_municipality_2')}
      </Box>
      
      {t('offer_municipality_3')}
      </Typography>
      
      <Box sx={{ paddingBottom: 2 }} />


    </Box>


  );
}