import * as React from 'react';
import { useState, useEffect } from 'react';
import mainImage from '../../components/Links/Rottensteiner Fotograf-22.jpg';
import ExtLink from '../../components/ExtLink';
import Box from '@mui/material/Box';
import CardsDownloads from './CardsDownloads';
import { useLocation } from 'react-router-dom';
import CardsDownload from './CardsDownload';
import { useTranslation } from "react-i18next";
import { useMatomo } from '@datapunt/matomo-tracker-react'

//import arrayOfCards_de from '../../../ Downloads/downloads_de.json';
//import arrayOfCards_de from '../../../../public/Downloads/downloads_de.json';
//import arrayOfCards_it from '../../../../public/Downloads/downloads_it.json';


export default function Downloads() {

  const { i18n } = useTranslation();
  const location = useLocation();

  const { trackPageView } = useMatomo()

  const [id, setId] = useState(null);
  const [list, setList ] = useState([]);

  useEffect(() => {
    if (location.search !== undefined) {
      var param = new URLSearchParams(location.search).get('id')
      setId(param);
    }
  }, [location.search])

  // fetch JSON File
  useEffect(() => {

    fetch(i18n.language==='de' ? 'Downloads/downloads_de.json' : 'Downloads/downloads_it.json')
      .then(response => response.json())
      .then(response => setList(response))
      .catch(error => {
          // handle the error
      });

  }, [i18n.language])

    // Track page view
    useEffect(() => {
      trackPageView({
        documentTitle: 'Downloads', // optional
        href: 'https://naturimgarten.laimburg.it' + location.pathname, // optional
        customDimensions: [
          {
            id: 1,
            value: 'loggedIn',
          },
        ], // optional
      })
    }, [trackPageView, location])

  return (

    < >
      <Box className='mainimagebox' >
        <img className='mainimage'
          src={mainImage}
          alt="Placeholder"
          width='50%'
        />
      </Box>


      <Box >
        {id === null ? <CardsDownloads list={list} /> : ''}

        {id !== null ? <CardsDownload key={id} item={list[id - 1]} /> : ''}
      </Box>


      <Box id='linebox'>
      </Box>
    </>

  );
}
