import * as React from 'react';
import { createTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Link } from '@mui/material';

const theme = createTheme({
    typography: {
        gtitle: {
            fontSize: '42pt',
            fontWeight: 600,
            color: '#00652b',
            lineHeight: '42pt',
            '@media (max-width: 992px)': {
                fontSize: '32pt',
            },
        },
        smtext: {
            fontSize: '12pt',
            color: 'green'
        },
        ctext: {
            fontSize: '16pt',
            color: 'green',
            textAlign: 'justify',
            fontWeight: '900'
        }
    },

});

export function ExtLink ({ link, text }) {

    return (

            <a href={link} target="_blank">
	        <Typography variant="ctext" theme={theme}> 
    	            {text}
	        </Typography>
            </a>

    )
}

export default ExtLink;