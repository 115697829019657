import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import BestPractCards from './BestPractCards';
import Carousel, { consts } from 'react-elastic-carousel';
import { Title } from '../../../components/Title';

import { useTranslation } from "react-i18next";

//import arrayOfCards_de from "./best_practice_de.json";
//import arrayOfCards_it from "./best_practice_it.json";

export default function BestPractBei() {

  const { i18n } = useTranslation();
  
  const [list, setList] = useState([]);

  useEffect(() => {

    fetch(i18n.language==='de' ? 'Best/best_practice_de.json' : 'Best/best_practice_it.json')
      .then(response => response.json())
      .then(response => setList(response))
      .catch(error => {
          // handle the error
      });

  }, [i18n.language])

  return (
    <div>

      <Box id='bestpractice'>
        <Box className='maintextbox' sx={{
          marginTop: 2,
        }}>

        <Title title="best_practice_examples" variant="title"></Title>


        </Box>
        <Box sx={{ textAlign: "center" }} className='carousel-g'>

          <Carousel
            itemsToShow={2}
            itemsToScroll={1}
            itemPadding={[0, 0, 0, 0]}
            enableSwipe={false}
            disableArrowsOnEnd={false}
            pagination={false}
            renderArrow={({ type, onClick }) => {
              const pointer = type === consts.PREV ? <SlArrowLeft className='arrows' /> :
                <SlArrowRight className='arrows' />
              return <button onClick={onClick} className='arrows'>{pointer}</button>
            }}
          >
            {list.map((row, index) => {return row.visible ? <BestPractCards key={index} image={row.image} title={row.title} body={row.body} /> : '' })}

          </Carousel>

        </Box>

        <Box sx={{ textAlign: "center" }} className='carousel-v'>

          <Carousel
            itemsToShow={1}
            itemsToScroll={1}
            itemPadding={[0, 5, 0, 0]}
            enableSwipe={false}
            disableArrowsOnEnd={false}
            pagination={false}
            renderArrow={({ type, onClick }) => {
              const pointer = type === consts.PREV ? <SlArrowLeft className='arrows' /> :
                <SlArrowRight className='arrows' />
              return <button onClick={onClick} className='arrows'>{pointer}</button>
            }}
          >

            {list.map((row, index) => {return row.visible ? <BestPractCards key={index} image={row.image} title={row.title} body={row.body} /> : '' })}

            

          </Carousel>

        </Box>

      </Box>



    </div>
  );
}
