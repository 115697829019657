import * as React from 'react';
import mainImage from '../../components/Links/NiG Schaugarten.jpg';
import Box from '@mui/material/Box';
import Wastunwir from './Wastunwir';
import UnserAng from './UnserAng';
import UnserAngDien from './UnsAngDien';
import Tours from './Tours';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useLocation } from 'react-router-dom';

export default function Dienstleistungen() {

  const { trackPageView  } = useMatomo() // trackEvent
  const location = useLocation();
    
  // Track page view
  React.useEffect(() => {
    trackPageView({
      documentTitle: 'Dienstleistungen', // optional
      href: 'https://naturimgarten.laimburg.it' + location.pathname, // optional
      customDimensions: [
        {
          id: 1,
          value: 'loggedIn',
        },
      ], // optional
    })
  }, [trackPageView])

  return (
    <Box>

      <Box className='mainimagebox' >
        <img className='mainimage'
          src={mainImage}
          alt="placeholder"
          width='100%'
        />
      </Box>

      <Wastunwir />

      <UnserAngDien />

      <UnserAng />

      <Tours />

    </Box>
  );
}
