import * as React from 'react';
import Box from '@mui/material/Box';
import Image from '../../components/Links/IMG_0655.JPG';
import Logo from '../../components/NiG_Logo_VERORTUNG_Südtirol.png';
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { HashLink } from "react-router-hash-link";
import { Title } from '../../components/Title';


const WButton = styled(Button)({
  borderRadius: 0,
  color: 'white',
  fontWeight: 'bold',
  fontSize: '18pt',
  textTransform: 'uppercase',
  border: 'white solid 1px',
  minWidth: '200px',
  Height: '60px',
  '&:hover': {
    border: 'white solid 1px',
  },
});


export default function UnserAng() {

  const { t } = useTranslation();

  const click = () => {
    window.scrollTo(0, 0)
  }

  return (

    <div>

      <div className='unser'>

        <Grid container spacing={0}>
          <Grid item xs={12} md={12} lg={6} className='unser-textbox'>

            <Box>

            <Title title="offer_for_garden" variant="wtitle"></Title>

              <Box sx={{ paddingBottom: 2 }} />
              {t('offer_for_garden_1')} <br />
              {t('offer_for_garden_2')}

            </Box>
            <Box sx={{ paddingBottom: 2 }} />
            <Grid container spacing={2}>

              <Grid item>
                <HashLink to="/private#criterias">
                  <WButton variant="outlined" onClick={click}>{t('criterias')}</WButton>
                </HashLink>
              </Grid>

              <Grid item>
                <HashLink to="/private#badge">
                  <WButton variant="outlined" onClick={click}>{t('plakette')}</WButton>
                </HashLink>
              </Grid>

            </Grid>

          </Grid>


          <Grid item xs={12} md={12} lg={6} className='unserimagebox'>
            <Box className='logo2'>
              <img src={Logo} width='300px' alt="logo"
              />
            </Box>
            <img src={Image} className='beratungen-im' width={'100%'} alt="IMG_0655" />


          </Grid>
        </Grid>
      </div>
    </div>





  );
}
