import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import { createTheme } from '@mui/material/styles';
import ColorButton from '../../components/ColorButton';
import GreenButton from '../../components/GreenButton';
import { Title } from '../../components/Title';
import { Link } from 'react-router-dom';


const theme = createTheme({
  typography: {
    gtitle: {
      fontSize: '32pt',
      fontWeight: 600,
      color: '#00652b',
      lineHeight: '42pt',
      '@media (max-width: 992px)': {
        fontSize: '32pt',
      },
    },
    ctextgb: {
      fontSize: '16pt',
      fontWeight: 600,
      color: '#00652b'
    },
    ctext: {
      fontSize: '16pt',
      color: 'black'
    }
  },

});

export default function CardD({ idCard, image, title, body }) {

  const { t } = useTranslation();

  const [link, setLink] = useState();

  useEffect(() => {
    setLink("?id=" + idCard)
  }, [idCard]);


  return (

    <Grid item xs={12} md={6} lg={6} sx={{ padding: '10px' }} >


    <Link to={link}>
      <Card sx={{ border: '1px solid #00652b', }} className='cards'>
        <CardActionArea>

            <CardMedia
              component="img"
              height="300"
              image={image}>
            </CardMedia>


          <CardContent>
            <Box className='cards-text-d'>

              <Title title={title} variant="h4"></Title>

              <Box className='pad' />
              <Typography variant="ctextgb" theme={theme}>
                {t(body)}
              </Typography>
            </Box>

          </CardContent>

          <GreenButton text="brochure_and_info" color="green" />

        </CardActionArea>

      </Card>
      </Link>
    </Grid>



  )
}