import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-http-backend";
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
      en: {
            translation: {
                  "Welcome to React": "Welcome to React and react-i18next"
            }
      },
      de: {
            translation: {
                  // Menu---------------------------------------------------------------------

                  "NIG": "Natur im Garten",
                  "services": "Dienstleistungen",
                  "municipalities": "Gemeinden",
                  "consultation": "Beratung",
                  "best_practice": "Best Practice",
                  "private": "Private",
                  "criterias": "Kriterien",
                  "kerncriterias": "Kernkriterien",
                  "badge_award": "Plakettenvergabe",
                  "tours": "Führungen",
                  "news&events": "News & Events",
                  "events": "Veranstaltungen",
                  "gardentips": "Gartentipps",
                  "downloads": "Downloads",
                  "news": "News",

                  // Natur im Garten----------------------------------------------------------
                  "what_is_nig": "Was ist „Natur im Garten“?",

                  "initiative_nig_1":
                        "Die Initiative „Natur im Garten“ startete 1999 in Niederösterreich und \
      fördert nachhaltige und klimataugliche Gärten und Grünflächen ",

                  "initiative_nig_2":
                        "Mit ökologisch wertvollen Pflanzenarten, abwechslungsreichen Strukturen und \
      natürlichen Bauweisen schaffen wir gesunde und vielfältige Lebensräume für \
      Mensch, Pflanzen und Tiere. Dabei wird großer Wert auf geschlossene \
      Kreisläufe, Biodiversität und eine Gestaltung mit heimischen Pflanzenarten \
      gelegt. Neben wichtigen Ökosystemdienstleistungen bieten naturnahe Grünflächen \
      Raum für Erholung, Entspannung sowie Natur-Erlebnis genau dort, wo wir leben \
      und arbeiten.",
		  "broschure.german": "(diese Broschüren sind nur in deutscher Sprache verfügbar)",

                  "vision_for_st":
                        "Daher lautet unsere Vision für Südtirol: „Wir wollen Grünflächen und \
      unsere Gesundheit fördern.“",

                  "3_criterias": "3 Kernkriterien",


                  "form_the_basis": "bilden im Naturgarten die Grundlage:",

                  "the_3_criterias": "Die drei Kernkriterien bilden die Grundlage für „Natur im Garten“, daher muss jedes dieser Kriterien zur Gänze erfüllt werden:",

                  "crit_1": "VERZICHT AUF TORF",

                  "crit_1_b":
                        "Durch den Abbau von Torf werden die in Mitteleuropa inzwischen selten gewordenen Moorlandschaften zerstört. Daher wird im Naturgarten weder im Gartenbereich noch für Topfpflanzen Torf verwendet. Bei der Verwendung von verpackten Pflanzenerden achten Sie bitte darauf, dass die angebotenen Erdmischungen keinen Torf enthalten.",

                  "crit_2":
                        "VERZICHT AUF CHEMISCH-SYNTHETISCHEN DÜNGER",
      
                  "crit_2_b":
                        "Kompostieren, Ansetzen von Jauchen, Mulchen und Gründüngung bilden die Grundlage für die Zufuhr von Nährstoffen sowie die Erhaltung und den Aufbau eines gesunden Bodens im Naturgarten. Chemisch-synthetische Dünger dagegen sind wasserlöslich und können ins Grundwasser gelangen, was zur Belastung unseres Trinkwassers beiträgt. Auch bei der Produktion dieser Düngemittel wird die Umwelt belastet. Benötigen die Pflanzen im Garten zusätzliche Nährstoffe, wird organisch gedüngt, am besten mit Kompost. ",
      
                  "crit_3":
                        "VERZICHT AUF CHEMISCH-SYNTHETISCHEN PESTIZIDE",

                  "crit_3_b":
                        "Vorbeugender Pflanzenschutz hält die Pflanzen gesund! Mit dem geeignete Pflanzenstandort, der Auswahl robuster Pflanzen, einer nachhaltigen und schonenden Bodenpflege und der Förderung von Nützlingen, ist der Einsatz von chemischen Pflanzenschutzmitteln langfristig unnötig. Eine Behandlung mit chemischen Pestiziden beeinträchtigt nicht nur Schädlinge, sondern auch Nützlinge und andere Organismen. Außerdem belastet die Produktion dieser Mittel die Umwelt. Daher kommen im Naturgarten nur umweltfreundliche Mittel zum Einsatz. ",

                  "nig_elements_title":
                        "Naturgartenelemente",

                  "nig_elements_1":
                        "Naturgartenelemente gestalten einen Garten vielfältig und stellen Lebensräume für verschiedenste Pflanzen- und Tiergruppen dar. Beispiele dafür sind Wildstrauchhecken, heimische Bäume, ökologisch wertvolle Blumen und Stauden, Naturwiesen, Trockensteinmauern, Sandflächen, Teiche..",

                  "nig_elements_2":
                        "Für diesen Bereich ist eine Mindestpunktezahl vorgesehen, welche für den Erhalt der Plakette erreicht werden muss.",

                  "nig_pflege_title":
                        "Pflege und Nutzung",

                  "nig_pflege_1":
                        "Der Naturgarten wird ressourcenschonend gepflegt, wobei auf die Nutzung von natürlichen Kreisläufen geachtet wird. So kann beispielsweise der eigene Kompost den Nährstoffbedarf des Gemüsegartens abdecken; Regenwasser dient der Bewässerung, Nützlinge werden als Regulatoren gegenüber Schädlingen gefördert.",

                  "nig_pflege_2":
                        "Für diesen Bereich ist eine Mindestpunktezahl vorgesehen, welche für den Erhalt der Plakette erreicht werden muss.",

                  "advise":
                        "„Unsere Beratungen und Forschungen \
      unterstützen einen gesunden Boden und die \
      Entwicklung resistenter Pflanzen. \
      Wir brauchen pflegeleichte Grünflächen, \
      die längere Trockenperioden überstehen \
      und trotzdem eine hohe Biodiversität zulassen.“",

                  "salchegger": "Dipl. Ing. Helga Salchegger, ",

                  "salchegger_leader":
                        "Leiterin des Fachbereichs Gartenbau am \
      Versuchszentrum Laimburg",

                  "who_are_we": "Wer sind wir?",

                  "who_are_we_1":
                        "Seit 2022 gilt der Fachbereich Gartenbau am Versuchszentrum Laimburg als offizielle Zertifizierungsstelle der „Natur im Garten“ Plakette.",

                  "who_are_we_2":
                        "Das Team rund um Helga Salchegger, besteht \
      aus Landschaftsplanerinnen und \
      Gärtnern. Wir können auf jahrzehntelange Erfahrung \
      in den Bereichen Garten- und Landschaftsbau, \
      Baumschulwesen, Permakultur, Zierpflanzenbau \
      und Projektmanagement zurückgreifen.",
                  "who_are_we_3": "v.l.n.r.: ",
                  "what_we_do": "Was tun wir?",

                  "Durch die praktische Arbeit in den Schauanlagen am Versuchszentrum Laimburg \
      sammeln wir hautnah Erfahrungen in der Anlage und Pflege von Grünflächen":
                        "Durch die praktische Arbeit in den Schauanlagen am Versuchszentrum Laimburg \
      sammeln wir hautnah Erfahrungen in der Anlage und Pflege von Grünflächen",



                  "what_we_do_1":
                        "Durch die praktische Arbeit in den Schauanlagen am Versuchszentrum Laimburg \
      sammeln wir hautnah Erfahrungen in der Anlage und Pflege von Grünflächen.",

                  "what_we_do_2":
                        "Durch vielfältige Beratungsangebote für Gartenbesitzerinnen, Gartenbesitzer und Gemeinden unterstützt der Fachbereich Gartenbau vom Versuchszentrum Laimburg die nachhaltige Gestaltung von privaten und öffentlichen Grünflächen.",

                  "what_we_do_3":
                        "Zusätzlich bieten wir Führungen zu verschiedenen Themen, die vom „Natur im Garten“ Schaugarten, über Gebäudebegrünung und Balkonversuchen bis hin zu Grünflächen an und um der Laimburg reichen.",

                  "what_we_do_4":
                        "Für uns ist die Vernetzung und der Austausch mit regionalen Akteurinnen und Akteuren aus den Bereichen Gartenbau, Wissenschaft, Politik und Tourismus eine wichtige Ressource. Unser Wissen geben wir in Form von Beratungen, Vorträgen und Publikationen weiter.",

                  "offer_for_garden":
                        "Unser Angebot für Privatgärten",

                  "offer_for_garden_1":
                        "Haben Sie einen vielfältigen Naturgarten? Dann kann die Natur im Garten-Plakette bald auch Ihren Gartenzaun schmücken!",

                  "offer_for_garden_2":
                        "Die Natur im Garten-Plakette ist eine Auszeichnung für naturnahe Gärten, die auf Torf, auf chemisch-synthetische Düngemittel und Pestizide verzichten, die eine vielfältige Gartengestaltung beinhalten und auf eine Pflege mit Bedacht auf lokale Kreisläufe achten.",

                  "plakette":
                        "Plakette",

                  // Dienstleistungen--------------------------------------------------------------
                  //Text3

                  "offer_municipality": "Unser Angebot für Gemeinden",

                  "offer_municipality_1":
                        "Die Expertinnen von „Natur im Garten“ unterstützen Südtiroler Gemeinden mit fundiertem Fachwissen zu verschiedenen Fragestellungen rund um öffentliche Grünflächen. Dabei legen wir besonderes Augenmerk auf Themen wie Pflegeextensivierung, Klimaanpassung, Baumpflege und Regenwassermanagement.",

                  // Gemeinden----------------------------------------------------------------------

                  "offer_municipality_2":
                        "Was kann eine Gemeinde für eine ökologische Aufwertung ihrer Grünflächen tun?",

                  "offer_municipality_3":
                        "Unser gemeinsames Ziel ist die ökologischen, sozialen, städtebaulichen und klimatischen Funktionen von Grünflächen in Südtirol nachhaltig zu sichern und zu verbessern. Eine durchdachte, standortgerechte Bepflanzung und Freiraumgestaltung kann den Auswirkungen des Klimawandels, den Düngerengpässen und reduzierten Verfügbarkeit der Pflanzenschutzmittel entgegenwirken und verringert gleichzeit Arbeitsaufwand und Ausgaben.",

                  // -------------------------------------

                  "municipalities_advice":
                        "Wie ist die Gemeindeberatung aufgebaut?",

                  "municipalities_advice_1": "Wir begleiten Gemeinden über eine längere Zeit.",

                  "municipalities_advice_2":
                        "Erstgespräch aller Beteiligten (Bürgermeisteramt, Gemeindereferat, Bauhof, Umweltgruppen, Gärtnereien, Tourismusverein) zu gemeinsamen Zielen",

                  "municipalities_advice_3":
                        "Besichtigung der Flächen",

                  "municipalities_advice_4":
                        "Kommunikation von Seiten der Gemeinde (Zeitungsartikel, Homepage)",

                  "municipalities_advice_5":
                        "Gemeinsame Organisation von Vorträgen für die Bevölkerung",

                  "municipalities_advice_6":
                        "Lösungsvorschläge für die ausgewählten Flächen",

                  "municipalities_advice_7":
                        "Umsetzungsphase und Unterstützung bei Fragen oder Schwierigkeiten",

                  // -----------------------------------------

                  "ask_for_advice_municipalities":
                        "Wenn Sie eine Grünflächen-Beratung für Ihre Gemeinde wünschen:",

                  "fill_out_formular":
                        "füllen Sie direkt das Anmelde-Formular aus",

                  "or": "oder",

                  "send_email_to": "senden Sie eine E-Mail an: ",

                  "nig_email": "naturimgarten@laimburg.it",

                  "best_practice_examples": "Best Practice Beispiele",

                  //Private------------------------------------------------------------------------

                  "how_to_get_badge": "Wie komme ich zu meiner „Natur im Garten“ Plakette?",

                  "how_to_get_badge_1":
                        "Die Plakette wird im Rahmen einer persönlichen Gartenbesichtigung verliehen, bei der die notwendigen Kriterien besprochen und allfällige Fragen zur Erhöhung der Biodiversität und zur Pflege beantwortet werden. Die Vergabe der Plakette ist kostenlos.",

                  "how_to_get_badge_2":
                        "Setzen Sie ein sichtbares Zeichen für mehr Nachhaltigkeit und Biodiversität in den Gärten Südtirols und melden sie sich bei Erfüllung der Kernkriterien für die Plakettenvergabe:",

                  "how_to_get_badge_3":
                        "Die drei Kernkriterien bilden die Grundlage für „Natur im Garten“, daher muss jedes dieser Kriterien zur Gänze erfüllt werden:",

                  "how_to_get_badge_4":
                        "In der Beratung werden auch Naturgartenelemente und Pflege angesprochen. Naturgartenelemente gestalten einen Garten vielfältig und schaffen Bedingungen für einen ausgewogenen Naturkreislauf: Wildstrauchhecken, Naturwiesen, Sonderstandorte wie Feucht- und Trockenbiotope, heimische Gehölze sowie ökologisch wertvolle Stauden sind Beispiele für Naturgartenelemente. Bei der Bewirtschaftung des Naturgartens wird auf einen Komposthaufen, Regenwassernutzung, eine umweltfreundliche Materialwahl, Mischkultur, Nützlingsunterkünfte sowie Mulchen großen Wert gelegt.",

                  "go_to_formular":
                        "Zum Formular",
                  
                  // Search
                  "initiative": "Initiative",
                  "team": "Team",

                  //"services": "Dienstleistungen",
                  "public_consultation": "Gemeindenberatung",
                  "green_areas": "Grünflächen",
                  "public_areas": "Öffentliche Grünflächen",
                  //"consultation": "Beratung",
                  //"best_practice": "Best Practice",
                  //"private": "Private",
                  "private_garden": "Privatgarten",
                  "gardener_him": "Privatgärtner",
                  "gardener_her": "Privatgärtnerin",
                  "garden": "Garten",

                  //"kerncriterias": "Kernkriterien",
                  "nig_elements": "Naturgartenelemente",
                  "care": "Pflege",
                  "use": "Nutzung",
                  "rationing": "Bewirtschaftung",

                  //"tours": "Führungen",
                  //"events": "Veranstaltungen",
                  "tips": "Gartentipps",
                  "tips_private": "Fachwissen Private",
                  "tips_public": "Fachwissen Gemeinden",
                  "garden_knowledge": "Gartenwissen",
                  "green_knowledge": "Grünraumwissen",

                  "infosheet": "Infoblätter",
                  "brochure": "Broschüren",
                  "formular":"Formular",
                  "contact": "Kontakt",


                  
                  // Kriterien --------------------------------------------------------------------

                  // Führungen---------------------------------------------------------------------

                  "tours_in_laimburg": "Welche Führungen bieten wir an?",

                  "tours_in_laimburg_1":
                        "Der Fachbereich Gartenbau bietet der Öffentlichkeit eine breite Auswahl an Fachführungen rund um das Thema „Natur im Garten“. Zur Besichtigung stehen unsere Schaugärten, die Dach- und Fassadenbegrünungen, der Balkonversuch sowie Grünflächen an und um der Laimburg.",

                  "tours_in_laimburg_2":
                        "Allgemeinen Informationen und organisatorischen Details finden Sie unter: ",

                  "tours_in_laimburg_3":
                        "oder wenden sie sich an Isabella Oss-Pintner: ",

                  "link_to_tours":
                        "https://www.laimburg.it/de/forschung/gartenbau.asp",

                  "tours_themes":"Themen der Fachführungen",
                  
                  "tour_1_title":"Schaugarten",
                  "tour_1_body":"Kräuter, Salatpflanzen, Duftpflanzen, Landsorten, Zitrus-Sammlung, tropische Nutzpflanzen",
                  "tour_1_time":"Dauer: 30 Minuten | Maximale Teilnehmerzahl: 15 Personen",

                  "tour_2_title":"Balkonversuch",
                  "tour_2_body":"(wechselt jährlich)",
                  "tour_2_time":"Dauer: 30 Minuten | Maximale Teilnehmerzahl: 15 Personen",

                  "tour_3_title":"Dachbegrünung",
                  "tour_3_body":"Extensive und intensive Aufbauten, Dachbegrünungen mit Biodiversität",
                  "tour_3_time":"Dauer: 40 Minuten | Maximale Teilnehmerzahl: 15 Personen",

                  "tour_4_title":"Fassadenbegrünungen",
                  "tour_4_body":"Wandgebundene Fassadenbegrünung und Begrünungen mit Kletterpflanzen. Welche Möglichkeiten gibt es? Vor- und Nachteile? Beispiele",
                  "tour_4_time":"Dauer: 30 Minuten | Maximale Teilnehmerzahl: 15 Personen",

                  "tour_5_title":"Natur-im-Garten-Schaugarten",
                  "tour_5_body":"Ein Familiengarten mit heimischen Pflanzenarten, Gemüsegarten, Hochbeete, Permakultur, naturnaher Pflege, ohne Torf, ohne Plastik und ohne mineralischen Dünger",
                  "tour_5_time":"Dauer: 30 Minuten | Maximale Teilnehmerzahl: 15 Personen",

                  "tour_6_title":"Grünflächen an und um die Laimburg",
                  "tour_6_body":"Asiatischer Garten, Schattengarten, Steingarten, Straßenbegleitgrün, Biopool, Rosengarten, Staudenflächen, Hecken, Biodiversität",
                  "tour_6_time":"Dauer: 60 Minuten | Maximale Teilnehmerzahl: 15 Personen",

                  // Cards-------------------------------------------------------------------------


                  "load_more_tips": "mehr Gartentipps laden",
                  "load_more_events": "mehr Veranstaltungen laden",

                  "Titel der Veranstaltung": "Titel der Veranstaltung",

                  "Uhr": "Uhr",

                  "Versuchszentrum Laimburg": "Versuchszentrum Laimburg",

                  "Fachwissen – Private": "Fachwissen für Private",

                  "Gartenwissen für PrivatgärtnerInnen": "Gartenwissen für PrivatgärtnerInnen",

                  "Fachwissen – Gemeinden": "Fachwissen für Gemeinden",

                  "Grünraumwissen": "Grünraumwissen",

                  // Footer------------------------------------------------------------------------

                  "laimburg_address": "Laimburg 6, Pfatten, 39040 Auer, Italien",

                  "laimburg_email": "versuchszentrum@laimburg.it",

                  "laimburg_phone": "+39 0471 969 500 | www.laimburg.it",

                  "Gleich anfragen.": "Gleich anfragen.",

                  "Nachricht": "Nachricht",

                  "Betreff": "Betreff",

                  "Ja, ich akzeptiere die Datenschutz Erklärung.":
                        "Ja, ich akzeptiere die Datenschutz Erklärung.",

                  "Impressum": "Impressum",

                  "AGB ́S'": "AGB ́S'",

                  "Datenschutz": "Datenschutz",
                  "back": "Zurück",
                  "open": "Öffnen",
                  "read_more": "Mehr erfahren",
                  "send_email": "Abschicken",
                  "file_list": "Liste Dateien",
                  "brochure_and_info": "Broschüren und infoblätter",
                  "subject": "Betreff",
                  "consultation_private": "Beratung Privatgärten",
                  "consultation_public": "Beratung Gemeinden",
                  "other": "Anderes",
                  "email": "Email",
                  "name": "Name",
                  "message": "Nachricht",
                  "checkbox_1": "Ich erfülle die 3 Kernkriterien zu Gänze.",
                  "checkbox_2": "Ja, ich akzeptiere die Datenschutz Erklärung.",
                  "book_right_now": "Buchen",
                  "impressum": "Impressum",
                  "agb": "AGB ́S",
                  "privacy": "Datenschutz",
                  "search...": "Suche...",
                  "search": "Suche",
                  "load_events": "mehr Events laden",
                  "close": "Schliessen",

                  "impressum_title" : "Haftungsausschluss (Disclaimer)",
                  "impressum_body": "Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.",
                  "logo": "logode",

                  "lecture": "Vorträge",
                  "lectures": "Vorträge Gartenbau",
                  "lecture.intro.1" : "Wir bieten Vorträge zwischen",
                  "lecture.intro.2" : "Oktober und März jeden Jahres",
                  "lecture.intro.3" : " an. Die Inhalte reichen von Privatgärten, öffentlichen Grünflächen bis zu Spezialthemen aus dem Gartenbau.",

                  "lecture.1.title": "1. Die Initiative Natur im Garten – Naturnahe Gärten gestalten und erhalten",
                  "lecture.1.body" : "Inhalt: Naturnahe Gärten sind anpassungsfähig und bieten vielfältige Lebensräume für heimische Pflanzen und Tiere. Mit der Initiative „Natur im Garten“ werden Gärtnerinnen und Gärtner unterstützt, Ihre Gärten ökologisch zu gestalten und zu pflegen und damit eine bunte Vielfalt, ohne Einsatz von Pestiziden, Kunstdüngern oder Torf zu fördern. Dabei wird auf die Hintergründe und Ziele der eingegangen. Ein weiterer Schwerpunkt werden die verschiedenen Strukturen und Elemente im Naturgarten sein. Praxisnahe Tipps und Pflanzenbeispiele sind Anregungen für einen eigenen Naturgarten.",
                  "lecture.1.time" : "Dauer: 60 min + Diskussion",

                  "lecture.2.title": "2. Öffentliche Grünflächen",
                  "lecture.2.body" : "Öffentliche und private Grünflächen müssen in Zukunft wichtige Funktionen übernehmen: Kühlung und Beschattung, Regenwassermanagement, Staubbindung, Erhöhung der Biodiversität, Erholungsraum, Nutzfläche für Obst, Gemüse und Kräuter, Lebensraum für Menschen. Gleichzeitig stehen wir vor neuen und alten Problemen. Hohe Pflegekosten und das fehlende Personal, die Reduktion zugelassener Mittel, die Preiserhöhung bei Düngern, die Sicherheit von Bäumen und Gehölzen bei Sturmereignissen, lange Trockenperioden und reduzierte Wasserverfügbarkeit.",
                  "lecture.2.time" : "Dauer: 60 min + Diskussion",
                  
                  "lecture.3.title": "3. Dach- und Fassadenbegrünungen",
                  "lecture.3.body" : "Die Begrünung von Dach- und Fassadenflächen sind Schnittstellen zwischen Bautechnik und Pflanzenverwendung. Die Begrünung von Bauwerken bringt Vorteile (Kühlung, Regenwasserspeicherung, Verlängerung der Lebensdauer von Abdichtungen, Wärmedämmung, Staubbindung, Ersatzlebensraum, Energiegewinnung), aber auch Investitionskosten. Seit 2005 werden extensive Dachbegrünungen am Versuchszentrum Laimburg untersucht. Die Ergebnisse der Versuche stellen Hilfestellung für die Planung, Ausführung und Pflege von Bauwerksbegrünungen dar.",
                  "lecture.3.time" : "Dauer: 60 min + Diskussion",
                  
                  "lecture.4.title": "4. Kompost im Hausgarten",
                  "lecture.4.body" : "Das Herz jedes Gartens ist der Komposthaufen. In ihm entsteht aus Bioabfällen wertvoller Biodünger und neue Erde. Kompost, das „schwarze Gold“, sorgt für einen ausgeglichenen Nährstoffnachschub und hilft, gesunde und lebendige Böden aufzubauen. Die Veranstaltung bietet eine Gelegenheit, mehr über die faszinierende Kunst der Kompostierung im eigenen Garten zu erfahren.",
                  "lecture.4.count.1" : "Die Bedeutung des Kompostierens",
                  "lecture.4.count.2" : "Was geschieht im Kompost?",
                  "lecture.4.count.3" : "Praktische Tipps",
                  "lecture.4.count.4" : "Fehler vermeiden",
                  "lecture.4.count.5" : "Kompostverwendung",
                  "lecture.4.ext" : "Für weitere Spezialthemen bitte per E-Mail anfragen.",
                  "lecture.4.time" : "Dauer: 60 min + Diskussion",

                "lecture.tail" : "Für weitere Spezialthemen bitte per E-Mail anfragen.",

                "mail.success.title": "Info",
                "mail.success.body": "Vielen Dank für die Anfrage. Wie werden uns mit Ihnen in Verbindung setzen!",
                "mail.error.title": "Fehler",
                "mail.error.body": "Fehler bei der Zusendung. Bitte probieren Sie es später nochmal."
            }
      },
      it: {
            translation: {
                  // Menu---------------------------------------------------------------------

                  "NIG": "Giardino naturale",
                  "services": "Servizi",
                  "municipalities": "Comuni",
                  "consultation": "Consultazione",
                  "best_practice": "Best Practice",
                  "private": "Privati",
                  "criterias": "Criteri",
                  "kerncriterias": "Criteri fondamentali",
                  "badge_award": "Certificazione e targhe",
                  "tours": "Visite guidate",
                  "news&events": "News & Events",
                  "events": "Eventi",
                  "gardentips": "Consigli Giardinaggio naturale",
                  "downloads": "Downloads",
                  "news": "News",

                  // Natur im Garten----------------------------------------------------------
                  "what_is_nig": "Cos'è „Giardino naturale“?",

                  "initiative_nig_1":
                        "\"Giardino naturale\" è un’iniziativa che promuove la progettazione e la manutenzione ecologica di giardini e spazi verdi ",
                  "initiative_nig_2":
                        "\I criteri fondamentali per ottenere la certificazione \"Giardino naturale\" prevedono che giardini e spazi verdi siano progettati e manutenuti senza prodotti fitosanitari e fertilizzanti di origine sintetica e che venga evitato l’utilizzo della torba nei substrati. Inoltre, devono essere creati degli habitat versatili per persone, animali e piante. Con il nostro lavoro vogliamo promuovere gli spazi verdi in Alto Adige",

		  "broschure.german": "(questi opuscoli sono disponibili solo in ligua tedesca)",

                  "vision_for_st":
                        "\"Con il nostro lavoro vogliamo promuovere gli spazi verdi in Alto Adige\"",

                  "3_criterias": "3 criteri fondamentali",
                  
                  "form_the_basis": "del \"Giardino naturale\":",

                  "the_3_criterias": "I tre criteri fondamentali costituiscono la base del concetto di \"Giardino naturale\". Ognuno di questi deve essere soddisfatto nella sua interezza:",

                  "crit_1": "NESSUN UTILIZZO DI TORBA",

                  "crit_1_b":
                        "L'estrazione della torba distrugge le torbiere che sono diventate rare in Europa centrale. Pertanto, viene evitato l'impiego di torba nel \"Giardino naturale\", sia nell'area del giardino sia per le piante in vaso. Quando si utilizzano terricci confezionati, è necessario accertarsi che le miscele proposte non contengano torba.",

                  "crit_2":
                        "NESSUN UTILIZZO DI FERTILIZZANTI CHIMICO-SINTETICI",
      
                  "crit_2_b":
                        "Il compostaggio, la preparazione di estratti vegetali, la pacciamatura e il sovescio costituiscono la base per l'apporto di sostanze nutritive e per il mantenimento e lo sviluppo di un terreno sano nel \"Giardino naturale\". I fertilizzanti chimici di sintesi, invece, sono solubili in acqua e possono penetrare nelle falde acquifere, contribuendo all'inquinamento dell'acqua potabile. La produzione di questi fertilizzanti, inoltre, inquina l'ambiente. Se le piante del giardino hanno bisogno di nutrienti aggiuntivi, devono essere concimate organicamente, preferibilmente con compost.",
      
                  "crit_3":
                        "NESSUN UTILIZZO DI PESTICIDI CHIMICI DI SINTESI",

                  "crit_3_b":
                        "La protezione preventiva mantiene le piante in salute. Con la scelta di piante robuste e con la collocazione corretta, con una cura del suolo sostenibile e delicata e la promozione di insetti utili, è possibile evitare l'uso di pesticidi chimici a lungo termine. Il trattamento con pesticidi chimici non colpisce solo gli animali infestanti, ma anche gli insetti utili e altri organismi. Inoltre, la produzione di questi agenti inquina l'ambiente. Pertanto, nel \"Giardino naturale\" è ammesso soltanto l'impiego di prodotti ecologici.",

                  "nig_elements_title":
                        "Elementi naturali",

                  "nig_elements_1":
                        "Gli elementi naturali rendono il giardino vario e forniscono habitat per un'ampia varietà di gruppi di piante e animali. Siepi di arbusti selvatici, alberi autoctoni, fiori e piante perenni di valore ecologico, come anche prati naturali, muretti a secco, aree sabbiose e stagni sono esempi di elementi naturali nel giardino.",

                  "nig_elements_2":
                        "Nel concetto di \"Giardino naturale\" è previsto un numero minimo di elementi naturali presenti, che deve essere raggiunto per ottenere la targa.",

                  "nig_pflege_title":
                        "Manutenzione e utilizzo",

                  "nig_pflege_1":
                        "Il \"Giardino naturale\" viene manutenuto in modo da preservarne le risorse, prestando attenzione ai cicli naturali. Ad esempio, il compost può coprire il fabbisogno di nutrienti dell'orto; l'acqua piovana viene utilizzata per l'irrigazione; gli insetti utili vengono incoraggiati come regolatori contro animali infestanti.",

                  "nig_pflege_2":
                        "Per quest'area è previsto un numero minimo di punti che deve essere raggiunto per ottenere la targa.",

                  "advise":
                        "„La nostra consulenza e le sperimentazioni scientifiche \
      sono volte a sostenere la salute del suolo e \
      lo sviluppo di piante resistenti. \
      Le aree verdi devono essere di facile manutenzione, \
      essere resistenti a periodi prolungati di siccità \
      e contemporaneamente promuovere la biodiversità.“",

                  "salchegger": "Dipl. Ing. Helga Salchegger, ",

                  "salchegger_leader":
                        "Responsabile settore Floricoltura e Paesaggistica al Centro di Sperimentazione Laimburg",

                  "who_are_we": "Chi siamo?",

                  "who_are_we_1":
                        "Dall'inizio del 2022, il settore Floricoltura e Paesaggistica del Centro di Sperimentazione Laimburg è l'ente certificatore ufficiale delle targhe \"Giardino naturale\".",

                  "who_are_we_2":
                        "Il team è composto da tre architetti paesaggisti e un maestro giardiniere. Possiamo contare su decenni di esperienza nei settori del floricoltura e della paesaggistica, del vivaismo, della permacultura e della coltivazione di piante ornamentali.",

                  "who_are_we_3": "Da sinistra a destra: ",
                  "what_we_do": "Cosa facciamo?",

                  "what_we_do_1":
                        "Attraverso il lavoro pratico nei giardini dimostrativi del Centro di Sperimentazione Laimburg acquisiamo un'esperienza diretta nella realizzazione e nella manutenzione degli spazi verdi.Attraverso una serie di servizi di consulenza per proprietari di giardini e per i Comuni, il settore Floricoltura e Paesaggistica del Centro di Sperimentazione Laimburg sostiene la progettazione sostenibile di spazi verdi privati e pubblici.",

                  "what_we_do_2":
                        "Attraverso una serie di servizi di consulenza per proprietari di giardini e per i Comuni, il settore Floricoltura e Paesaggistica del Centro di Sperimentazione Laimburg sostiene la progettazione sostenibile di spazi verdi privati e pubblici.",

                  "what_we_do_3":
                        "Inoltre, offriamo visite guidate su vari temi, che vanno dal giardino espositivo \"Giardino naturale\", all'inverdimento degli edifici e alle prove sui balconi, fino agli spazi verdi nell'areale Laimburg e dintorni.",

                  "what_we_do_4":
                        "Il networking e lo scambio con gli attori regionali dei settori della floricoltura, della scienza, della politica e del turismo sono per noi una risorsa importante. Trasmettiamo le nostre conoscenze sotto forma di consulenze, conferenze e pubblicazioni.",


                        
                        
                        
                  // Servizi--------------------------------------------------------------
                  //Text3

                  "offer_municipality": "La nostra offerta per i Comuni",

                  "offer_municipality_1":
                        "Le nostre esperte e i nostri esperti di \"Giardino naturale\" supportano i Comuni dell'Alto Adige con una solida competenza su diverse tematiche legate al verde pubblico. Particolare attenzione viene prestata a temi quali la riduzione della manutenzione, l'adattamento al clima, la gestione delle alberature e dell'acqua piovana.",

                  // Comuni----------------------------------------------------------------------

                  "offer_municipality_2":
                        "Cosa può fare un'amministrazione comunale per valorizzare i propri spazi verdi?",

                  "offer_municipality_3":
                        "Il nostro obiettivo è garantire e migliorare in modo sostenibile le funzioni ecologiche, sociali, urbanistiche e climatiche degli spazi verdi in Alto Adige. Una progettazione ben ponderata e adeguata al sito può contrastare gli effetti del cambiamento climatico, della carenza di fertilizzanti e della ridotta disponibilità di pesticidi, riducendo al contempo il carico di lavoro e le spese.",

                  // -------------------------------------

                  "municipalities_advice":
                        "Come è strutturata la consulenza per i Comuni?",

                  "municipalities_advice_1": "Accompagniamo le amministrazioni comunali in un percorso a lungo termine.",

                  "municipalities_advice_2":
                        "Incontro iniziale di tutte le parti coinvolte (ufficio del sindaco, dipartimento comunale, dipartimento cantieri, gruppi ambientalisti, imprese di giardinaggio vivai, associazioni turistiche) per discutere gli obiettivi comuni.",

                  "municipalities_advice_3":
                        "Sopralluogo per ispezionare le aree d’interesse",

                  "municipalities_advice_4":
                        "Comunicazione da parte del Comune (articoli di giornale, homepage).",

                  "municipalities_advice_5":
                        "Organizzazione congiunta per presentare il progetto alle cittadine e ai cittadini",

                  "municipalities_advice_6":
                        "Proposte di soluzioni per le aree selezionate",

                  "municipalities_advice_7":
                        "Fase di implementazione e supporto in caso di domande o difficoltà.",

                  // -----------------------------------------

                  "ask_for_advice_municipalities":
                        "Se desiderate richiedere una consulenza per le aree verdi del vostro Comune:",

                  "fill_out_formular":
                        "compilate direttamenta il modulo online",

                  "or": "oppure",

                  "send_email_to": "inviate un'email a: ",

                  "nig_email": "giardinonaturale@laimburg.it",

                  "best_practice_examples": "Esempi di buona pratica",

                  //Privati------------------------------------------------------------------------

                  "offer_for_garden":
                        "La nostra offerta per giardini privati",

                  "offer_for_garden_1":
                        "Se il vostro giardino soddisfa i criteri per i giardini orientati alla natura potete richiedere la certificazione \"Giardino naturale\".",

                  "offer_for_garden_2":
                        "La targa \"Giardino naturale\" è un riconoscimento per gli spazi verdi naturali, ovvero giardini dove non vengono utilizzati torba, fertilizzanti e pesticidi chimico-sintetici. Inoltre, in un “Giardino naturale” viene progettato includendo una diversificazione delle strutture e degli habitat e vengono rispettati i cicli naturali.",

                  "plakette":
                        "Targa",
     
                  "how_to_get_badge": "Come si ottiene la targa \"Giardino naturale\"?",

                  "how_to_get_badge_1":
                        "La targa viene assegnata durante un sopralluogo da parte di un esperto del Centro di Sperimentazione Laimburg, durante il quale si discutono i criteri necessari e si risponde alle domande sull'aumento della biodiversità e sulla gestione. Il rilascio della targa è gratuito.",

                  "how_to_get_badge_2":
                        "Con la certificazione \"Giardino naturale\" viene data una maggiore visibiltà alla sostenibilità e alla biodiversità dei giardini dell'Alto Adige:",

                  "how_to_get_badge_3":
                        "I tre criteri fondamentali costituiscono la base del concetto di \"Giardino naturale\". Ognuno di questi deve essere soddisfatto nella sua interezza:",

                  "how_to_get_badge_4":
                        "Anche gli elementi naturali del giardino e la sua cura sono oggetto della consulenza. Gli elementi naturali del giardino danno forma a un giardino variegato e creano le condizioni per un ciclo naturale equilibrato: siepi di arbusti selvatici, prati naturali, siti speciali come biotopi umidi e secchi, piante legnose autoctone e piante perenni di valore ecologico sono esempi di elementi naturali del giardino. Nella gestione del \"Giardino naturale\" si attribuisce grande importanza a una compostiera, all'utilizzo dell'acqua piovana, a una scelta ecologica dei materiali, alla coltivazione mista, ai rifugi per gli insetti utili e alla pacciamatura.",

                  "go_to_formular":
                        "Modulo",
                  
                  // Search
                  "initiative": "Initiativa",
                  "team": "Team",

                  //"services": "Dienstleistungen",
                  "public_consultation": "Consultazione comuni",
                  "green_areas": "Spazi verdi",
                  "public_areas": "Spazi verdi pubblici",
                  //"consultation": "Beratung",
                  //"best_practice": "Best Practice",
                  //"private": "Private",
                  "private_garden": "Giardino privato",
                  "gardener_him": "Giardiniere privato",
                  "gardener_her": "Giardiniera privata",
                  "garden": "Giardino",

                  //"kerncriterias": "Kernkriterien",
                  "nig_elements": "Elementi naturali",
                  "care": "Manutenzione",
                  "use": "Utilizzo",
                  "rationing": "Bewirtschaftung",

                  //"tours": "Visite guidate",
                  //"events": "Eventi",
                  "tips": "Consigli giardinaggio naturale",
                  "tips_private": "Informazioni techniche privati",
                  "tips_public": "Informazioni tecniche comuni",
                  "garden_knowledge": "Informazioni su giardini",
                  "green_knowledge": "Informazioni sulla paesaggistica",

                  "infosheet": "Opuscoli",
                  "brochure": "Fogli informativi",
                  "formular":"Modulo",
                  "contact": "Contatto",


                  // Criteri --------------------------------------------------------------------

                  // Visite guidate---------------------------------------------------------------------

                  "tours_in_laimburg": "Quali visite guidate offriamo?",

                  "tours_in_laimburg_1":
                        "Il settore Floricoltura e Paesaggistica del Centro di Sperimentazione Laimburg offre al pubblico un'ampia gamma di visite specialistiche sul tema \"Giardino naturale\". I nostri giardini espositivi, i tetti e le facciate verdi, le prove sperimentali per balconi e le aree verdi all'interno e intorno all'areale Laimburg sono disponibili per la visita.",

                  "tours_in_laimburg_2":
                        "Allgemeinen Informationen und organisatorischen Details finden Sie unter: ",

                  "tours_in_laimburg_3":
                        "oder wenden sie sich an Isabella Oss-Pintner: ",

                  "link_to_tours":
                        "https://www.laimburg.it/de/forschung/gartenbau.asp",

                  "tours_themes":"Themen der Fachführungen",
                  
                  "tour_1_title":"Giardino espositivo",
                  "tour_1_body":"Erbe, piante da insalata, piante aromatiche, varietà di terra, collezione di agrumi, piante utili tropicali",
                  "tour_1_time":"Durata: 30 Minuti | Numero massimo di partecipanti: 15 Persone",

                  "tour_2_title":"Varietà da balcone",
                  "tour_2_body":"(esposizione varia ogni anno)",
                  "tour_2_time":"Durata: 30 Minuti | Numero massimo di partecipanti: 15 Persone",

                  "tour_3_title":"Inverdimento dei tetti",
                  "tour_3_body":"Strutture estensive e intensive, tetti verdi con focus sulla biodiversità",
                  "tour_3_time":"Durata: 40 Minuti | Numero massimo di partecipanti: 15 Persone",

                  "tour_4_title":"Inverdimento delle facciate",
                  "tour_4_body":"Inverdimento di facciate murate, inverdimento con piante rampicanti, opzioni, vantaggi e svantaggi",
                  "tour_4_time":"Durata: 30 Minuti | Numero massimo di partecipanti: 15 Persone",

                  "tour_5_title":"Giardino naturale",
                  "tour_5_body":"Giardino e orto espositivo con specie vegetali autoctone in supporti rialzati, permacultura, curato senza utilizzo di torba, plastica e fertilizzanti minerali.",
                  "tour_5_time":"Durata: 30 Minuti | Numero massimo di partecipanti: 15 Persone",

                  "tour_6_title":"Spazi verdi a Laimburg e dintorni",
                  "tour_6_body":"Giardino asiatico, giardino d'ombra, giardino roccioso, verde stradale, la piscina naturale, roseto, zone perenni, siepi e biodiversità.",
                  "tour_6_time":"Durata: 60 Minuti | Numero massimo di partecipanti: 15 Persone",
                  
                  // Eventi-------------------------------------------------------------------------
                  "load_more_tips": "mostra altri consigli",
                  "load_more_events": "mostra eltri eventi",

                  // Footer------------------------------------------------------------------------

                  "laimburg_address": "Laimburg 6, Vadena, 39040 Ora, Italia",

                  "laimburg_email": "versuchszentrum@laimburg.it",

                  "laimburg_phone": "+39 0471 969 500 | www.laimburg.it",

                  "Gleich anfragen.": "Richiedi ora",

                  "Nachricht": "E-mail",

                  "Betreff": "Oggetto",

                  "Ja, ich akzeptiere die Datenschutz Erklärung.":
                        "Sie, accetto l'informativa sulla privacy.",

                  "Impressum": "Impressum",

                  "AGB ́S'": "AGB ́S'",

                  "Datenschutz": "Privacy",
                  "back": "Indietro",
                  "open": "Apri",
                  "read_more": "Per saperne di più",
                  "send_email": "Invia",
                  "file_list": "Elenco file",
                  "brochure_and_info": "Opuscoli e fogli informativi",
                  "subject": "Soggetto",
                  "consultation_private": "Consultazione giardini privati",
                  "consultation_public": "Consultazione aree pubbliche",
                  "other": "Altro",
                  "email": "Email",
                  "name": "Nome",
                  "message": "Messaggio",
                  "checkbox_1": "Si, soddisfo pienamente i 3 criteri fondamentali.",
                  "checkbox_2": "Si, accetto le condizioni di privacy.",
                  "book_right_now": "Prenota",
                  "impressum": "Informazioni legali",
                  "agb": "Condizioni generali",
                  "privacy": "Privacy",
                  "search...": "Cerca...",
                  "search": "Cerca",
                  "load_events": "carica altri eventi",
                  "close": "Chiudi",

                  "impressum_title" : "Dichiarazione liberatoria (Disclaimer)",
                  "impressum_body": "La nostra offerta può contenere link a siti esterni di terze parti, sul cui contenuto non abbiamo alcuna influenza. Per i contenuti esterni non possiamo assumere alcuna responsabilità. Del contenuto delle pagine esterne offerte da terzi sono responsabili l’offerente o il gestore del sito. Al momento della connessione non son ostati individuati, nei siti terzi connessi attraverso un link, contenuti illegali. Un controllo permanente dei loro contenuti appare irragionevole, in assenza di prove concrete di violazioni della legge. Nel caso in cui queste ultime dovessero essere accertate provvederemo immediatamente alla loro rimozione.",

                  "logo": "logoit",

                  "lecture": "Vorträge",
                  "lectures": "Vorträge Gartenbau",
                  "lecture.intro.1" : "Wir bieten Vorträge zwischen",
                  "lecture.intro.2" : "Oktober und März jeden Jahres",
                  "lecture.intro.3" : " an. Die Inhalte reichen von Privatgärten, öffentlichen Grünflächen bis zu Spezialthemen aus dem Gartenbau.",

                  "lecture.1.title": "1. Die Initiative Natur im Garten – Naturnahe Gärten gestalten und erhalten",
                  "lecture.1.body" : "Inhalt: Naturnahe Gärten sind anpassungsfähig und bieten vielfältige Lebensräume für heimische Pflanzen und Tiere. Mit der Initiative „Natur im Garten“ werden Gärtnerinnen und Gärtner unterstützt, Ihre Gärten ökologisch zu gestalten und zu pflegen und damit eine bunte Vielfalt, ohne Einsatz von Pestiziden, Kunstdüngern oder Torf zu fördern. Dabei wird auf die Hintergründe und Ziele der eingegangen. Ein weiterer Schwerpunkt werden die verschiedenen Strukturen und Elemente im Naturgarten sein. Praxisnahe Tipps und Pflanzenbeispiele sind Anregungen für einen eigenen Naturgarten.",
                  "lecture.1.time" : "Dauer: 60 min + Diskussion",

                  "lecture.2.title": "2. Öffentliche Grünflächen",
                  "lecture.2.body" : "Öffentliche und private Grünflächen müssen in Zukunft wichtige Funktionen übernehmen: Kühlung und Beschattung, Regenwassermanagement, Staubbindung, Erhöhung der Biodiversität, Erholungsraum, Nutzfläche für Obst, Gemüse und Kräuter, Lebensraum für Menschen. Gleichzeitig stehen wir vor neuen und alten Problemen. Hohe Pflegekosten und das fehlende Personal, die Reduktion zugelassener Mittel, die Preiserhöhung bei Düngern, die Sicherheit von Bäumen und Gehölzen bei Sturmereignissen, lange Trockenperioden und reduzierte Wasserverfügbarkeit.",
                  "lecture.2.time" : "Dauer: 60 min + Diskussion",
                  
                  "lecture.3.title": "3. Dach- und Fassadenbegrünungen",
                  "lecture.3.body" : "Die Begrünung von Dach- und Fassadenflächen sind Schnittstellen zwischen Bautechnik und Pflanzenverwendung. Die Begrünung von Bauwerken bringt Vorteile (Kühlung, Regenwasserspeicherung, Verlängerung der Lebensdauer von Abdichtungen, Wärmedämmung, Staubbindung, Ersatzlebensraum, Energiegewinnung), aber auch Investitionskosten. Seit 2005 werden extensive Dachbegrünungen am Versuchszentrum Laimburg untersucht. Die Ergebnisse der Versuche stellen Hilfestellung für die Planung, Ausführung und Pflege von Bauwerksbegrünungen dar.",
                  "lecture.3.time" : "Dauer: 60 min + Diskussion",
                  
                  "lecture.4.title": "4. Kompost im Hausgarten",
                  "lecture.4.body" : "Das Herz jedes Gartens ist der Komposthaufen. In ihm entsteht aus Bioabfällen wertvoller Biodünger und neue Erde. Kompost, das „schwarze Gold“, sorgt für einen ausgeglichenen Nährstoffnachschub und hilft, gesunde und lebendige Böden aufzubauen. Die Veranstaltung bietet eine Gelegenheit, mehr über die faszinierende Kunst der Kompostierung im eigenen Garten zu erfahren.",
                  "lecture.4.count.1" : "Die Bedeutung des Kompostierens",
                  "lecture.4.count.2" : "Was geschieht im Kompost?",
                  "lecture.4.count.3" : "Praktische Tipps",
                  "lecture.4.count.4" : "Fehler vermeiden",
                  "lecture.4.count.5" : "Kompostverwendung",
                  "lecture.4.time" : "Dauer: 60 min + Diskussion",

                "lecture.tail" : "Für weitere Spezialthemen bitte per E-Mail anfragen.",

                "mail.success.title": "Informazione",
                "mail.success.body": "Grazie per la sua richiesta. A Breve La contatteremo.",
                "mail.error.title": "Errore",
                "mail.error.body": "Errore durante l'invio. Si prega di riprovare più tardi."

            }
      },
};

i18n
      .use(Backend)
      .use(LanguageDetector)
      .use(XHR) // <---- add this
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({
            detection: { order: ["path", "navigator"] },
            resources,
            fallbackLng: ['de'],
            whitelist: ["de", "it"],
            interpolation: {
                  escapeValue: false // react already safes from xss
            }
      });

export default i18n;
