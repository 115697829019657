import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { useTranslation } from "react-i18next";
import { createTheme } from '@mui/material/styles';
import ColorButton from '../../components/ColorButton';
import { Title } from '../../components/Title';
import { Link } from 'react-router-dom';

const theme = createTheme({
    typography: {
        gtitle: {
            fontSize: '32pt',
            fontWeight: 600,
            color: '#00652b',
            lineHeight: '42pt',
            '@media (max-width: 992px)': {
                fontSize: '32pt',
            },
        },
        ctextgb: {
            fontSize: '16pt',
            fontWeight: 600,
            color: '#00652b'
        },
        ctext: {
            fontSize: '16pt',
            color: 'black'
        }
    },

});

export default function CardG({ idCard, image, title, gbody, body }) {
    const { t } = useTranslation();

    //const link = "?id="+idCard;
    const [link, setLink] = useState();

    useEffect(() => {
        

        setLink("?id=" + idCard)
    }, [idCard]);

    let im = "Gardentips/".concat(image);

    return (



        <>
        <Link to={link} >
            <Card sx={{ border: '1px solid #00652b', }} className='cards'>
                <CardActionArea >

                    <CardMedia
                        component="img"
                        height="300"
                        image={im}
                    />

                    <CardContent sx={{ margin: '0px' }}>

                        <Box className='cards-text-gt'>
                            
                            <Title title={title} variant="title"></Title>

                            <Box className='pad' />
                            <Typography variant="ctextgb" theme={theme}>
                                {t(gbody)}
                            </Typography>
                            <Box className='pad' />
                            <Typography variant="ctext" theme={theme}>
                                {t(body)}
                            </Typography>
                        </Box>

                    </CardContent>

                    <ColorButton text="read_more" link={link} color="green"/>

               </CardActionArea>

            </Card>
        </Link>
        </>
      


    )
}