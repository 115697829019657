import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { Title } from '../../../components/Title';

const theme = createTheme({
  typography: {
    wtitle: {
      fontSize: '42pt',
      fontWeight: 600,
      color: 'white',
      lineHeight: '42pt',
      '@media (max-width: 992px)': {
        fontSize: '32pt',
      },
    },
    wtext: {
      fontSize: '18pt',
      color: 'white',
      paddingTop: '50px',
      '@media (max-width: 992px)': {
        fontSize: '16pt',
      },
    }
  },
});


export default function WieIstDieGem() {

  const { t } = useTranslation();

  return (
    <Box id="consultation" sx={{
      color: 'white',
      backgroundColor: '#00652b',
    }} className='maintextbox' >

 
      <Title title="municipalities_advice" variant="wtitle"></Title>

      <Box className='pad' />

      <Typography variant='wtext' theme={theme}>
        {t('municipalities_advice_1')}
        <Box sx={{ paddingBottom: 2 }} />
        • {t('municipalities_advice_2')}
        <Box sx={{ paddingBottom: 2 }} />
        • {t('municipalities_advice_3')}
        <Box sx={{ paddingBottom: 2 }} />
        • {t('municipalities_advice_4')}
        <Box sx={{ paddingBottom: 2 }} />
        • {t('municipalities_advice_5')}
        <Box sx={{ paddingBottom: 2 }} />
        • {t('municipalities_advice_6')}
        <Box sx={{ paddingBottom: 2 }} />
        • {t('municipalities_advice_7')}

      </Typography>



    </Box>


  );
}