import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Icon1 from '../../../components/icon_1.png';
import Icon2 from '../../../components/icon_2.png';
import Icon3 from '../../../components/icon_3.png';
import { useTranslation } from "react-i18next";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Title } from '../../../components/Title';

export default function KernkritPriv() {

  const { t } = useTranslation();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (

    <div id='criterias' >

      <Box className='greybox' >

        <Title title="kerncriterias" variant="title"></Title>

        <Box className='greentitle' >
          {t('the_3_criterias')}
        </Box>

        <div className='generaliconsbox'>
          <Grid container spacing={0}>

            <Grid item xs={12} md={4} lg={4} className='iconbox'>

              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="pan_1"
                >
                  <div className="accordion-icon">
                    <img src={Icon1} alt="Icon 1" />
                    <br />

                    <Typography sx={{
                      fontSize: '22pt',
                      color: 'green',
                      fontFamily: 'inherit',
                      textTransform: 'none',
                    }}>
                      {t('crit_1')}
                    </Typography>
                  </div>

                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{
                    fontSize: '14pt',
                    color: 'black',
                    fontFamily: 'inherit',
                    textTransform: 'none',
                  }}
                  >
                    {t('crit_1_b')}
                  </Typography>
                </AccordionDetails>
              </Accordion>

            </Grid>

            <Grid item xs={12} md={4} lg={4} className='iconbox'>

              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="pan_2"
                >
                  <div className="accordion-icon">
                    <img src={Icon2} alt="Icon 2" />
                    <br />

                    <Typography sx={{
                      fontSize: '22pt',
                      color: 'green',
                      fontFamily: 'inherit',
                      textTransform: 'none',
                    }}>
                      {t('crit_2')}
                    </Typography>
                  </div>

                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{
                    fontSize: '14pt',
                    color: 'black',
                    fontFamily: 'inherit',
                    textTransform: 'none',
                  }}
                  >
                    {t('crit_2_b')}
                  </Typography>
                </AccordionDetails>
              </Accordion>

            </Grid>
            <Grid item xs={12} md={4} lg={4} className='iconbox'>

              <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="pan_3"
                >
                  <div className="accordion-icon">
                    <img src={Icon3} alt="Icon 3" />
                    <br />

                    <Typography sx={{
                      fontSize: '22pt',
                      color: 'green',
                      fontFamily: 'inherit',
                      textTransform: 'none',
                    }}>
                      {t('crit_3')}
                    </Typography>
                  </div>

                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{
                    fontSize: '14pt',
                    color: 'black',
                    fontFamily: 'inherit',
                    textTransform: 'none',
                  }}
                  >
                    {t('crit_3_b')}
                  </Typography>
                </AccordionDetails>
              </Accordion>

            </Grid>

          </Grid>
        </div>



      </Box>


    </div>


  );
}