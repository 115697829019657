import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
import { createTheme } from '@mui/material/styles';
import { Title } from '../../../components/Title';


const theme = createTheme({
  typography: {
    gtitle: {
      fontSize: '42pt',
      fontWeight: 600,
      color: '#00652b',
      lineHeight: '42pt',
      '@media (max-width: 992px)': {
        fontSize: '32pt',
      },
    },
    text: {
      fontSize: '18pt',
      color: 'black',
      '@media (max-width: 992px)': {
        fontSize: '16pt',
      },
    }
  },

});

const anchors = document.querySelectorAll('a[href*="#"]')

for (let anchor of anchors) {
  anchor.addEventListener("click", function (event) {
    event.preventDefault();
    const blockID = anchor.getAttribute('href')
    document.querySelector('' + blockID).scrollIntoView({
      behavior: "smooth",
      block: "start"
    })
  })
}

export default function Pflege () {

  const { t } = useTranslation();

  return (

      <>
       <Box id="care" className='greybox' sx={{
      color: 'white',
      backgroundColor: '#00652b',
      marginTop: '10px'
    }}>


        <Title title="nig_pflege_title" variant="wtitle" ></Title>
        
        <Typography variant='text' paragraph={true} theme={theme} color="white">
        {t('nig_pflege_1')}
        </Typography>

        <Typography variant='text' paragraph={true} theme={theme} color="white">
        {t('nig_pflege_2')}
        </Typography>
   
      </Box>
   
      </>
  );
}