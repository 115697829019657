import * as React from 'react';
import Box from '@mui/material/Box';
import Image from '../../Images/Tour.jpg';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import { Title } from '../../components/Title';
import { HashLink } from "react-router-hash-link";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';


const WButton = styled(Button)({
  borderRadius: 0,
  color: 'white',
  fontWeight: 'bold',
  fontSize: '18pt',
  textTransform: 'uppercase',
  border: 'white solid 1px',
  minWidth: '200px',
  Height: '60px',
  '&:hover': {
    border: 'white solid 1px',
  },
});

const click = () => {
  window.scrollTo(0, 0)
}

export default function Tours() {

  const { t } = useTranslation();

  return (

    <div className='unser' id="consulation">

      <Grid container spacing={0} className='unsdien'>

        <Grid item xs={12} md={12} lg={6} id="team">
          <img src={Image} className='beratungen-im' alt="IMG_1088" width='100%' />
        </Grid>

        <Grid item xs={12} md={12} lg={6} className='unser-textbox'>

          <Title title="tours_in_laimburg" variant="wtitle"></Title>

          <Box sx={{ paddingBottom: 2 }} />

          {t('tours_in_laimburg_1')}

          <Box sx={{ paddingBottom: 2 }} />
          <Grid container spacing={2}>

              <Grid item>
                <HashLink to="/tours">
                  <WButton variant="outlined" onClick={click}>{t('read_more')}</WButton>
                </HashLink>
              </Grid>

              <Grid item>
                <HashLink to="https://laris.scientificnet.org/extern/tour/start" target="_blank">
                  <WButton variant="outlined" onClick={click}>{t('book_right_now')}</WButton>
                </HashLink>
              </Grid>

            </Grid>

        </Grid>

        
 
      </Grid>

    </div>

  );
}