import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";
import { createTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { Title } from '../../../components/Title';
import { TextBlack } from '../../../components/TextBlack';


const GButton = styled(Button)({
  borderRadius: 0,
  color: 'white',
  backgroundColor: '#00652b',
  fontWeight: 'bold',
  fontSize: '16pt',
  textTransform: 'uppercase',
  minWidth: '210px',
  minHeight: '60px',
  '&:hover': {
    backgroundColor: '#00652b',
  },
});

const theme = createTheme({
  typography: {
    gtitle: {
      fontSize: '42pt',
      fontWeight: 600,
      color: '#00652b',
      lineHeight: '42pt',
      '@media (max-width: 992px)': {
        fontSize: '32pt',
      },
    },
    text: {
      fontSize: '18pt',
      color: 'black',
      '@media (max-width: 992px)': {
        fontSize: '16pt',
      },
    }
  },
});


const anchors = document.querySelectorAll('a[href*="#"]')

for (let anchor of anchors) {
  anchor.addEventListener("click", function (event) {
    event.preventDefault();
    const blockID = anchor.getAttribute('href')
    document.querySelector('' + blockID).scrollIntoView({
      behavior: "smooth",
      block: "start"
    })
  })
}

export default function GetPlackette() {

  const { t } = useTranslation();

  let mailto = "mailto:".concat(t('nig_email'));

  return (

  
    <>

      <Box id="badge" className='maintextbox' sx={{
        marginTop: 2,
      }}>

        <Title title="how_to_get_badge" variant="title"></Title>


        <Box className='pad' />

        <TextBlack text="how_to_get_badge_1"></TextBlack>

        <TextBlack text="how_to_get_badge_2"></TextBlack>

        <TextBlack text="how_to_get_badge_3"></TextBlack>

        <TextBlack text="how_to_get_badge_4"></TextBlack>

        <Typography variant='text' theme={theme}>
          <Box sx={{ paddingBottom: 2 }} />
          
          <Box
            sx={{
              color: '#006c38',
            }}>
 

            <Box sx={{ paddingBottom: 2 }} />
              {t("fill_out_formular")}
              <br />
              {t('or')}
              <br />
              {t("send_email_to")}
            
            <Link href={mailto} sx={{ color: '#00652b', textDecorationColor: '#00652b', }}>
              {t('nig_email')}
            </Link>

          </Box>
        </Typography>

        <Box sx={{ paddingBottom: 2 }} />

        <GButton variant="contained" size="large" href='#formular' sx={{
          m: 1,

          '&.Mui-checked': {
            color: '#00652b',

          },
          width: '250px',
          height: '60px',
        }}>
          {t("go_to_formular")}
        </GButton>

      </Box>
    </>
  );
}