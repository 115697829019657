import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { Title } from '../../components/Title';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';

import ExtLink from '../../components/ExtLink';
import TextBlack from '../../components/TextBlack';


const GButton = styled(Button)({
    borderRadius: 0,
    color: 'white',
    backgroundColor: '#00652b',
    fontWeight: 'bold',
    fontSize: '16pt',
    textTransform: 'uppercase',
    minWidth: '210px',
    minHeight: '60px',
    '&:hover': {
        backgroundColor: '#00652b',
    },
});


export default function CardsDownload({ item }) {

    const { t } = useTranslation();
    
    const location = useLocation();

    const { trackPageView } = useMatomo();


    const goBack = () => {
        window.scrollTo(0, 0)
    }

    const handleDownload = (str) => () =>  {

        let fileName = item.downloads[str];
        let prefix = item.prefix;

        trackPageView({
            documentTitle: 'Downloads', // optional
            href: 'https://naturimgarten.laimburg.it'+location.pathname+fileName, // optional
            customDimensions: [
            {
                id: 1,
                value: 'loggedIn',
            },
            ], // optional
        });


        fetch("Downloads/"+prefix+"/"+fileName).then(response => {
            response.blob()
            .then(blob => {
                // Creating new object of PDF file
            
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = fileName;
                alink.click();
            })
        })
    }


    // Track page view
    useEffect(() => {
	if (item !== undefined)
        trackPageView({
            documentTitle: 'Downloads', // optional
            href: 'https://naturimgarten.laimburg.it'+location.pathname+item.title, // optional
            customDimensions: [
            {
                id: 1,
                value: 'loggedIn',
            },
            ], // optional
        })
        }, [trackPageView, item, location])
    
    return (

        <>
        <div className='maintextbox'>
	    {item !== undefined &&
	    <>
            <Title title={item.title} variant="title"></Title>

            <Box className='pad' />

            <Box key={item.id} className='mainimagebox' >
            <a href={item.link} className='hyperlink' rel="noreferrer" target="_blank">
                <img className='mainimage' id="nig_at"
                    key={item.id} 
                    src={item.image}
                    alt="main"
                    width='100%'
                />
            </a>
		
            </Box>

		<Box sx={{padding: '10px'}}>
		    <TextBlack text={item.linktext} para={true}></TextBlack>
    		    <ExtLink link={item.link} text={item.link}></ExtLink>
		    <TextBlack text='broschure.german' para={true}></TextBlack>
		</Box>


            <List
                sx={{
                    width: '100%',
                    bgcolor: 'background.paper'
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader
                        component="div"
                        id="nested-list-subheader">
                            {t('file_list')}
                    </ListSubheader>
                }
            >
                {item.downloads.map((item, index) => {
                    return (
                            <ListItemButton key={index} onClick={handleDownload(index)}>
                                <ListItemIcon>
                                    <InboxIcon />
                                </ListItemIcon>
                                
                                <ListItemText primary={item}>
                                </ListItemText>
                            </ListItemButton>
                    )
                })}
            </List>

            <Box sx={{ textAlign: 'right', padding: '20px' }}>

                <Link to="/downloads" onClick={goBack}>
                    <GButton variant="contained" size="large" >
                        {t('back')}
                    </GButton>
                </Link>

            </Box>
	    </>
	    }

        </div>
        </>
    );
}
