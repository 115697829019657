import * as React from 'react';
import { useLocation } from 'react-router-dom';
import mainImage from '../../../components/Links/Snapshot_23-03-09_14-50-00.png';
import Box from '@mui/material/Box';
import UnsAngPriv from './UnsAngPriv';
import PicturesPriv from './PicturesPriv';
import KernkritPriv from './KernkritPriv';
import GetPlackette from './GetPlackette';
import NIGElements from './NIGElements';
import Pflege from './Pflege';
import { useMatomo } from '@datapunt/matomo-tracker-react'

export default function Private() {

  let location = useLocation();

  const { trackPageView } = useMatomo()

  React.useEffect(() => {
    let lo = location.hash.substring(1);
    if (lo)
    {
      let el = document.getElementById(lo);
      if (el != null)
        el.scrollIntoView()
    }
  }, [location]);

  // Track page view
  React.useEffect(() => {
    trackPageView({
      documentTitle: 'Dienstleistungen Private', // optional
      href: 'https://naturimgarten.laimburg.it' + location.pathname, // optional
      customDimensions: [
        {
          id: 1,
          value: 'loggedIn',
        },
      ], // optional
    })
  }, [trackPageView])
  
  return (

    <Box>

      <Box className='mainimagebox' >
        <img className='mainimage'
          src={mainImage}
          alt="main"
          width='100%'
        />
      </Box>

      <UnsAngPriv />

      <KernkritPriv />

      <NIGElements />

      <Pflege />

      <GetPlackette />

      <PicturesPriv />

    </Box>
  );
}
