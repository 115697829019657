import * as React from 'react';
import { useTranslation } from "react-i18next";
import { createTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const theme = createTheme({
  typography: {
    text: {
      fontSize: '16pt',
      color: 'black',
      '@media (max-width: 992px)': {
        fontSize: '16pt',
      },
    },
  },
});


export function TextBlack ({text, para=true}) {

  const { t } = useTranslation();

  return (
    <>
      <Typography className="textbody" variant="text" theme={theme} paragraph={para}>
          {t(text)}
        </Typography>
    </>
  )
}

export default TextBlack;