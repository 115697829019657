import * as React from 'react';
import Box from '@mui/material/Box';
import Image from '../../components/Links/Raif Okt 18 Bestand.JPG';
import HS from '../../Images/HelgaSalchegger.png';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';

export default function Beratungen() {

  const { t } = useTranslation();

  return (
    <div>
      <div className='unser' id="consulation">

        <Grid container spacing={0} alignItems='center'>

          <Grid item xs={12} md={6} lg={6} className='unser-textbox'>

            <Box sx={{
              fontWeight: 'bold',
            }}>
              {t('advise')}
            </Box>

            <Box sx={{ paddingBottom: 2 }} />

            <Grid container alignItems="center">
            
              <Grid item xs={12} sm={4} >
                <img src={HS} height={'200px'} alt="Helga Salchegger"></img> 
              </Grid>
              
              <Grid item xs={12} sm={8}
                style={{
                  fontSize: '16pt',
                  verticalAlign: 'middle',
                  padding: '10px'
              }}>

                {t('salchegger')}
                <br />
                {t('salchegger_leader')}

              </Grid>
              

            </Grid>

          </Grid>


          <Grid item xs={12} md={6} lg={6} className='beratungen'>
            <img src={Image} className='beratungen-im' alt="consultation" width={'100%'} />


          </Grid>
        </Grid>
      </div>

    </div>
  );
}