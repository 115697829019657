import * as React from 'react';
import Box from '@mui/material/Box';
import { Title } from '../../components/Title';
import { TextBlack } from '../../components/TextBlack';
import { TextGreen } from '../../components/TextGreen';
import { ExtLink } from '../../components/ExtLink';

export default function TextBox() {


  return (
    <Box id="initiative" className='maintextbox' sx={{
      marginTop: 2,
    }}>

      <Title title="what_is_nig" variant="title"></Title>

      <TextBlack text='initiative_nig_1' para={false}></TextBlack>
      <ExtLink link="https://www.naturimgarten.at" text='"Natur im Garten"'></ExtLink>. <TextBlack text='initiative_nig_2' para={false}></TextBlack>

      <TextGreen text='vision_for_st'></TextGreen>

    </Box>


  );
}

